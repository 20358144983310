import React, { useCallback, useMemo, useState } from "react"
import { Box, Container, Heading, VStack, Text, Flex, Image, HStack, Button, Spinner, Center } from "@chakra-ui/react"

import { useShopify } from "@app/hooks/useShopify"
import { useImage } from "@app/hooks/useImage"
import { Icon } from "@app/components/Icon"
import PersonaliserShippingWidget from "@app/components/Personaliser/PersonaliserShippingWidget"
import PersonaliserOrderSummaryItem from "@app/components/Personaliser/PersonaliserOrderSummaryItem"
import PersonaliserSuccessWidget from "@app/components/Personaliser/PersonaliserSuccessWidget"
import PersonaliserUpsellsWidget from "@app/components/Personaliser/PersonaliserUpsellsWidget"

import { useCart } from "@app/hooks/useCart"
import { useBundle } from "@app/hooks/useBundle"
import CustomAlert from "@app/components/CustomAlert"

import { NormalisedProduct } from "@root/types/custom-types/Product/Product"
import {
  OrderSummaryData,
  PersonaliserAdditional,
  Bundle,
  PersonaliserSummaryData,
  OnAddProductClickHandler,
  OnRemoveClickHandler,
  Addon,
  OnAddToSelectionHandler,
  OnRemoveFromSelectionHandler,
  ModalImage,
} from "@root/types/custom-types/Personaliser/Data"

type Props = {
  data: OrderSummaryData
  additional: PersonaliserAdditional
  bundle: Bundle
  setBundle: React.Dispatch<React.SetStateAction<Bundle>>
  resetBundle: () => void
  summary: PersonaliserSummaryData
  back: string
  newLabel: string
  edit: string
  setStep: React.Dispatch<React.SetStateAction<number>>
  step: number
  setIsProductModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isProductModalOpen: boolean
  setModalProduct: React.Dispatch<React.SetStateAction<NormalisedProduct | undefined>>
  onAddProductClickHandler: OnAddProductClickHandler
  onRemoveClickHandler: OnRemoveClickHandler
  selectedUpsellAddons: Addon[] | undefined
  setSelectedUpsellAddons: React.Dispatch<React.SetStateAction<Addon[] | undefined>>
  onAddToSelectionHandler: OnAddToSelectionHandler
  onRemoveFromSelectionHandler: OnRemoveFromSelectionHandler
  setIsImageModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setModalImages: React.Dispatch<React.SetStateAction<ModalImage[]>>
  labelLoading: boolean
}

const PersonaliserOrderSummary: React.FC<Props> = ({
  data,
  additional,
  bundle,
  setBundle,
  resetBundle,
  summary,
  back,
  newLabel,
  edit,
  setStep,
  step,
  setIsProductModalOpen,
  isProductModalOpen,
  setModalProduct,
  onAddToSelectionHandler,
  onRemoveFromSelectionHandler,
  selectedUpsellAddons,
  setSelectedUpsellAddons,
  setIsImageModalOpen,
  setModalImages,
  labelLoading,
}) => {
  const { formatMoney, getFirstAvailableVariant } = useShopify()
  const { getGatsbyImage } = useImage()
  const { addToCartMultiple } = useCart()
  const { addBundleToCart } = useBundle()

  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showUpsellModal, setShowUpsellModal] = useState(false)
  const [atcLoading, setAtcLoading] = useState(false)

  const { summaryTotal } = summary
  const { title, freeShippingThreshold, freeShippingProgress, freeShippingReceived, addToCart, upsellData } = data

  const total = useMemo(() => {
    return Object.values(bundle).reduce((stepsTotal, step) => {
      if (!Array.isArray(step) || !step.length) return stepsTotal

      if ("id" in step[0]) {
        return (stepsTotal += (step as NormalisedProduct[]).reduce((acc, product) => {
          const availableVariant = getFirstAvailableVariant(product)
          if (!availableVariant) return acc

          return (acc += parseFloat(availableVariant.priceV2.amount))
        }, 0))
      } else {
        return (stepsTotal += (step as Addon[]).reduce((acc, addOn) => {
          const availableVariant = getFirstAvailableVariant(addOn.product)
          if (!availableVariant) return acc

          return (acc += parseFloat(availableVariant.priceV2.amount) * addOn.qty)
        }, 0))
      }
    }, 0)
  }, [bundle, getFirstAvailableVariant])

  const onBackClickHandler = useCallback(() => setStep(prevStep => prevStep - 1), [setStep])
  const onEditLabelHandler = useCallback(() => setStep(3), [setStep])
  const onNewLabelHandler = useCallback(() => setStep(2), [setStep])
  const onEnlargeLabelHandler = useCallback(
    (image: ModalImage) => {
      if (labelLoading) return

      setModalImages([image])
      setIsImageModalOpen(true)
    },
    [setModalImages, setIsImageModalOpen, labelLoading]
  )

  const onAddToCartHandler = useCallback(async () => {
    await addBundleToCart({
      bundle,
      setAtcLoading,
      addToCartMultiple,
      labelTitle: "Build your own",
      bundleImage: "https://cdn.shopify.com/s/files/1/0474/3895/4647/files/BYO_Bundle.png?v=1723864344",
      URL: "https://www.theneighbourscellar.com.au/build-your-own-gift-box",
    })
    setShowSuccessModal(true)
  }, [addBundleToCart, setShowSuccessModal, bundle, setAtcLoading, addToCartMultiple])

  const step3BundleData = bundle["step3"]
  const finalLabelImage = step3BundleData?.finalImage
  const labelImage = !finalLabelImage ? getGatsbyImage(bundle.step2?.image) : { src: finalLabelImage }

  /**
   * Show error message if the customer has an invalid gift box in their bundle.
   * We need to stop them from adding to cart and send them back to update their giftbox in this instance.

   * A gift box is considered invalid if it is a add ons box with no add ons in the bundle or if it
   * is a no add ons box with add ons in the bundle
   */
  const isGiftBoxInvalid = useMemo(() => {
    return (
      (!!bundle.step5?.length && bundle.step5[0].tags.includes(additional.noAddOnsTag) && !!bundle.step4?.length) ||
      (!!bundle.step5?.length && bundle.step5[0].tags.includes(additional.addOnsTag) && !bundle.step4?.length)
    )
  }, [bundle.step4, bundle.step5, additional.noAddOnsTag, additional.addOnsTag])

  const isAddToCartDisabled = useMemo(() => isGiftBoxInvalid || labelLoading === true, [isGiftBoxInvalid, labelLoading])

  const resetGiftBox = useCallback(() => {
    setStep(5)
    setBundle(prevBundle => ({
      ...prevBundle,
      step5: undefined,
    }))
  }, [setStep, setBundle])

  return (
    <Box as="section" py={{ base: "6", lg: "12" }} px={{ base: "4", lg: "20" }}>
      <PersonaliserSuccessWidget
        data={data.success}
        resetBundle={resetBundle}
        showSuccessModal={showSuccessModal}
        setShowSuccessModal={setShowSuccessModal}
      />

      <PersonaliserUpsellsWidget
        data={data.upsellData}
        additional={additional}
        setShowUpsellModal={setShowUpsellModal}
        showUpsellModal={showUpsellModal}
        setIsProductModalOpen={setIsProductModalOpen}
        isProductModalOpen={isProductModalOpen}
        bundle={bundle}
        setBundle={setBundle}
        setModalProduct={setModalProduct}
        step={step}
        onAddToSelectionHandler={onAddToSelectionHandler}
        onRemoveFromSelectionHandler={onRemoveFromSelectionHandler}
        selectedUpsellAddons={selectedUpsellAddons}
        setSelectedUpsellAddons={setSelectedUpsellAddons}
      />

      <Container maxW="125" px="0">
        {title ? (
          <Heading size="h4" mb={{ base: "3", lg: "6" }} textAlign="center">
            {title}
          </Heading>
        ) : null}

        {isGiftBoxInvalid && additional.giftboxErrorMessage ? (
          <CustomAlert status="error" withoutIcon={true} mb={{ base: "3", lg: "6" }}>
            {additional.giftboxErrorMessage}

            {additional.giftboxErrorButtonLabel ? (
              <Text as="button" onClick={resetGiftBox} variant="underlined" ml="1">
                {additional.giftboxErrorButtonLabel}
              </Text>
            ) : null}
          </CustomAlert>
        ) : null}

        {labelLoading && additional.labelFinaliseWarning ? (
          <CustomAlert status="warning" withoutIcon={true} mb={4}>
            {additional.labelFinaliseWarning}
          </CustomAlert>
        ) : null}

        <PersonaliserShippingWidget
          freeShippingThreshold={freeShippingThreshold}
          freeShippingProgress={freeShippingProgress}
          freeShippingReceived={freeShippingReceived}
          addToCart={addToCart}
          orderTotal={total}
          upsellCtaLabel={upsellData.ctaButtonLabel}
          setShowUpsellModal={setShowUpsellModal}
        />

        <VStack spacing={{ base: "4", lg: "5" }} alignItems="stretch" mt={{ base: "4", lg: "6" }} py={{ base: "4", lg: "5" }}>
          <>
            {labelImage && bundle.step3 && labelLoading === false ? (
              <Flex alignItems="center" gap="5">
                <Box
                  onClick={() => onEnlargeLabelHandler(labelImage)}
                  pos="relative"
                  bg="background.beige"
                  borderRadius="xl"
                  overflow="hidden"
                  w="30"
                  _before={{ d: "block", w: "full", pb: "100%", content: '""' }}
                  cursor={!labelLoading ? "pointer" : "initial"}
                >
                  <Image
                    {...labelImage}
                    pos="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    w="calc(100% - 16px)"
                    h="auto"
                    maxH="100%"
                  />
                </Box>

                <Box>
                  {bundle.step3.textLine1 ? <Text size="mediumParagraph">{bundle.step3.textLine1}</Text> : null}
                  {bundle.step3.textLine2 ? <Text size="mediumParagraph">{bundle.step3.textLine2}</Text> : null}

                  <HStack spacing="2">
                    <Text as="button" size="textLinkSmall" variant="underlined" onClick={onEditLabelHandler}>
                      {edit}
                    </Text>

                    <Text as="button" size="textLinkSmall" variant="underlined" onClick={onNewLabelHandler}>
                      {newLabel}
                    </Text>
                  </HStack>
                </Box>
              </Flex>
            ) : null}

            {!labelImage || !bundle.step3 || labelLoading === true ? (
              <Flex alignItems="center" gap="5">
                <Box
                  pos="relative"
                  bg="background.beige"
                  borderRadius="xl"
                  overflow="hidden"
                  w="30"
                  _before={{ d: "block", w: "full", pb: "100%", content: '""' }}
                >
                  <Center pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" w="calc(100% - 8px)" h="auto" maxH="100%">
                    <Spinner thickness="2px" speed="0.4s" emptyColor="background.white" color="brand.secondaryFocus" size="md" />
                  </Center>
                </Box>

                {additional.labelFinaliseWarning ? (
                  <Box>
                    <Text size="mediumParagraph">{additional.labelFinaliseMessage}</Text>
                  </Box>
                ) : null}
              </Flex>
            ) : null}

            {Object.values(bundle).map((step, index) => {
              if (!step) return null

              if (Array.isArray(step)) {
                if (!step.length) return null

                return step.map((item, productIndex) => {
                  const product = "id" in item ? item : item.product
                  const variant = product.variants.find(v => v.availableForSale && v.quantityAvailable)
                  const amount = parseFloat(variant?.priceV2.amount)
                  const price = "id" in item ? amount : amount * item.qty

                  return (
                    <PersonaliserOrderSummaryItem
                      key={`product-${productIndex}-${index}`}
                      image={product.images[0]}
                      title={product.title}
                      subtitle={product.vendor}
                      price={price}
                      qty={"qty" in item ? item.qty : undefined}
                    />
                  )
                })
              }

              // TODO: Need help identifying bug with key error
              // There are unique keys, but the browser still complains that they're not unique
              if ("giftcard" in step) {
                const { sticker, giftcard } = step

                return (
                  <>
                    {sticker.product ? (
                      <PersonaliserOrderSummaryItem
                        key={`sticker-${sticker.product.handle}-${index}`}
                        image={sticker.product.images[0]}
                        title={sticker.product.title}
                        subtitle={sticker.date?.toLocaleDateString()}
                        price={getFirstAvailableVariant(sticker.product)?.priceV2?.amount || 0}
                      />
                    ) : null}

                    {giftcard.product ? (
                      <PersonaliserOrderSummaryItem
                        key={`giftcard-${giftcard.product.handle}-${index}`}
                        image={giftcard.product.images[0]}
                        title={giftcard.product.title}
                        subtitle={giftcard?.message}
                        price={getFirstAvailableVariant(giftcard.product)?.priceV2?.amount || 0}
                      />
                    ) : null}
                  </>
                )
              }

              return null
            })}
          </>
        </VStack>

        {!showSuccessModal && !showUpsellModal ? (
          <Box
            pos={{ base: "fixed", lg: "unset" }}
            bottom={{ base: "0", lg: "unset" }}
            left={{ base: "50%", lg: "unset" }}
            transform={{ base: "translateX(-50%)", lg: "unset" }}
            maxW="133"
            w="full"
            zIndex="popover"
            px={{ base: "4", lg: "0" }}
            py={{ base: "4", lg: "0" }}
          >
            <Box boxShadow="subtleBottomGlow" borderRadius="xl" overflow="hidden" bg="background.white">
              <Box p="4">
                <Flex justifyContent="space-between">
                  <Text size="smSemiLarge">{summaryTotal}</Text>
                  <Text size="smSemiLarge">{formatMoney(total)}</Text>
                </Flex>
              </Box>

              <Flex>
                <Box
                  as="button"
                  d="inline-flex"
                  alignItems="center"
                  gap="1"
                  py="4"
                  px="3"
                  bg="background.grey100"
                  onClick={onBackClickHandler}
                >
                  <Box as="span" w="5" h="5">
                    <Icon name="arrows/chevron-left" width="100%" height="100%" />
                  </Box>
                  <Text size="labels">{back}</Text>
                </Box>

                <Box
                  as={Button}
                  d="inline-flex"
                  justifyContent="center"
                  alignItems="center"
                  flexGrow={1}
                  bg="brand.primary"
                  color="background.white"
                  transition="background 0.2s ease"
                  _hover={{
                    bg: "brand.primaryFocus",
                  }}
                  onClick={onAddToCartHandler}
                  isLoading={atcLoading}
                  borderRadius="none"
                  border="none"
                  isDisabled={isAddToCartDisabled}
                >
                  <Text size="labels">{addToCart}</Text>
                </Box>
              </Flex>
            </Box>
          </Box>
        ) : null}
      </Container>
    </Box>
  )
}

export default React.memo(PersonaliserOrderSummary)
