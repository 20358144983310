import React, { useMemo } from "react"
import { AspectRatio, Box, Flex, Image, Text } from "@chakra-ui/react"

import { useShopify } from "@app/hooks/useShopify"
import { useImage } from "@app/hooks/useImage"

import { NormalisedImage } from "@app/hooks/useShopify"

type Props = {
  image?: GatsbyTypes.SanityCustomImage | NormalisedImage | string
  title?: string
  subtitle?: string
  price: number
  qty?: number
}

const PersonaliserOrderSummaryItem: React.FC<Props> = ({ image, title, subtitle, price, qty }) => {
  const { getGatsbyImage } = useImage()
  const { formatMoney } = useShopify()

  const customImage = useMemo(() => getGatsbyImage(image), [getGatsbyImage, image])
  const formattedPrice = useMemo(() => formatMoney(price), [formatMoney, price])

  return customImage ? (
    <Flex gap="4" alignItems="center" justifyContent="space-between">
      <Box w={{ base: "14" }}>
        <AspectRatio ratio={1 / 1} w="full">
          {typeof customImage == "string" ? <Image src={customImage} /> : <Image {...customImage} />}
        </AspectRatio>
      </Box>

      <Box flex={1}>
        {title ? (
          <Text size="largeParagraph" color="typography.headlines900">
            {title} {qty ? `x ${qty}` : null}
          </Text>
        ) : null}
        {subtitle ? <Text size="mediumParagraph">{subtitle}</Text> : null}
      </Box>

      <Text size="largeParagraph" w={{ base: "17" }} textAlign="right">
        {formattedPrice}
      </Text>
    </Flex>
  ) : null
}

export default React.memo(PersonaliserOrderSummaryItem)
