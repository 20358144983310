import React, { useCallback, useMemo, useState } from "react"
import { AspectRatio, Badge, BadgeProps, Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react"

import { useImage } from "@app/hooks/useImage"
import { useShopify, useShopifyPrice, useShopifyProduct } from "@app/hooks/useShopify"
import PersonaliserProductPrice from "@app/components/Personaliser/PersonaliserProductPrice"
import { Icon } from "@app/components/Icon"

import { NormalisedProduct } from "@root/types/custom-types/Product/Product"
import QuantityInput from "../Input/QuantityInput"
import { Bundle, Addon, OnAddProductClickHandler, OnRemoveClickHandler } from "@root/types/custom-types/Personaliser/Data"

export type ProductItemProps = {
  item: NormalisedProduct
  textAdd: string
  textAbout: string
  textOnSale: string
  textSelected: string
  bundle: Bundle
  selectedAddons?: Addon[]
  step: number
  setIsProductModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setModalProduct: React.Dispatch<React.SetStateAction<NormalisedProduct | undefined>>
  onAddProductClickHandler: OnAddProductClickHandler
  onRemoveClickHandler: OnRemoveClickHandler
}

const PersonaliserProductItem: React.FC<ProductItemProps> = ({
  item,
  textAdd,
  textAbout,
  textOnSale,
  textSelected,
  bundle,
  selectedAddons,
  step,
  setIsProductModalOpen,
  setModalProduct,
  onAddProductClickHandler,
  onRemoveClickHandler,
}) => {
  const { getGatsbyImage } = useImage()
  const { getProductTagValue } = useShopifyProduct()
  const { getFirstAvailableVariant } = useShopify()

  const [qty, setQty] = useState(1)

  const customImages = getGatsbyImage(item.images)
  const initialVariant = getFirstAvailableVariant(item)

  const { onSale } = useShopifyPrice(initialVariant)

  const badge = useMemo(() => getProductTagValue("badge:", item.tags) || "", [getProductTagValue, item.tags])

  const badgeCommonStyles: BadgeProps = {
    pos: "absolute",
    top: "0",
    left: "0",
    zIndex: "overlay",
  }
  const onModalClickHandler = useCallback(() => {
    setModalProduct(item)
    setIsProductModalOpen(true)
  }, [setModalProduct, setIsProductModalOpen, item])

  let addOnData = bundle["step4"]?.length ? bundle["step4"] : []
  if (step === 7) addOnData = selectedAddons?.length ? selectedAddons : []
  const inBundle = !!addOnData?.some(addOn => addOn.product.id === item.id)

  if (!initialVariant) return null

  return (
    <Flex flexDir="column" justifyContent="space-between">
      <Box>
        <Box onClick={onModalClickHandler} pos="relative" cursor="pointer">
          {badge ? <Badge {...badgeCommonStyles}>{badge}</Badge> : null}

          {onSale ? (
            <Badge variant="tertiary" {...badgeCommonStyles}>
              {textOnSale}
            </Badge>
          ) : null}

          <AspectRatio ratio={1 / 1} mb="3">
            <Image {...customImages[0]} />
          </AspectRatio>
        </Box>

        <Box>
          <Heading as="h5" size="headlines" color="typography.headlines900">
            {item.title}
          </Heading>

          {item.vendor ? (
            <Text size="mediumParagraph" mb="2">
              {item.vendor}
            </Text>
          ) : null}

          <PersonaliserProductPrice variant={initialVariant} />

          <Button onClick={onModalClickHandler} variant="unstyled" d="inline-flex" gap="1" h="auto" mb="3">
            <Box as="span" w="4" h="4">
              <Icon name="information-circle" width="100%" height="100%" />
            </Box>

            <Text size="smallParagraph" variant="underlined">
              {textAbout}
            </Text>
          </Button>
        </Box>
      </Box>

      {step === 4 || step === 7 ? (
        <QuantityInput min={1} max={initialVariant?.quantityAvailable} setQty={setQty} disabled={inBundle} />
      ) : null}

      {(step === 4 || step === 7) && inBundle ? (
        <Button variant="solid" w="full" onClick={() => onRemoveClickHandler(item.id, 4)}>
          {textSelected}
        </Button>
      ) : (
        <Button variant="outline" w="full" onClick={() => onAddProductClickHandler(item, qty)}>
          {textAdd}
        </Button>
      )}
    </Flex>
  )
}

export default React.memo(PersonaliserProductItem)
